import type {PageProps} from 'gatsby'
import {graphql, navigate} from 'gatsby'
import React from 'react'

import MDXRendererLayout from '@/components/mdx/mdx-renderer'
import type {GetPageQuery} from '@/graphql-types'

import '@pleo-io/telescope-tokens/dist/color.css'
import '@pleo-io/telescope-tokens/dist/color-scheme-light.css'
import '@pleo-io/telescope-tokens/dist/color-scheme-dark.css'
import '@pleo-io/telescope-tokens/dist/border.css'

const Home = (props: {data: GetPageQuery} & PageProps) => {
    if (props.path === '/') {
        navigate('/home/home.landing')
    }

    return <MDXRendererLayout {...props} />
}

export default Home

export const query = graphql`
    query getHomePage {
        mdx(slug: {eq: "docs/pages/home/home.landing"}) {
            frontmatter {
                title
                tabs
                intro
                layoutMode
            }
            body
            headings {
                depth
                value
            }
        }
    }
`
